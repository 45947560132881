import { techStackDetails } from "../Details";

function Technologies() {
  const {
    html,
    css,
    js,
    typescript,
    angular,
    dotnetcore,
    chash,
    tailwind,
    bootstrap,
    sass,
    node,
    xd,
    react,
    vscode,
    git,
    github,
    npm,
    postman,
    figma,
  } = techStackDetails;
  return (
    <main className="container mx-auto max-width pt-10 pb-20 section ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={html} title="html" alt="" />
        <img src={css} title="CSS" alt="" />
        <img src={js} title="JavaScript" alt="" />
        <img
          src={typescript}
          title="TypeScript"
          alt=""
          width={100}
          height={100}
        />
        <img src={angular} title="Angular" alt="" width={120} height={120} />
        <img
          src={dotnetcore}
          title=".Net Core"
          alt=""
          width={120}
          height={120}
        />
        <img src={chash} title="C#" alt="" width={100} height={100} />
        <img src={tailwind} title="Tailwind CSS" alt="" />
        <img src={bootstrap} title="Bootstrap" alt="" />
        <img src={sass} title="SASS" alt="" />
        <img src={node} title="Node" alt="" />
        <img src={react} title="React JS" alt="" />
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={vscode} title="Visual Studio Code" alt="" />
        <img src={git} title="Git" alt="Git" />
        <img
          src={github}
          title="Github"
          alt="Github"
          width={100}
          height={100}
        />
        <img src={figma} title="Figma" alt="Figma" width={70} height={70} />
        <img
          src={xd}
          title="Adobe XD"
          alt="Adobe XD"
          width={100}
          height={100}
        />
        <img src={npm} title="NPM" alt="NPM" />
        <img src={postman} title="Postman" alt="Postman" />
      </section>
    </main>
  );
}

export default Technologies;
